import { cn } from "~/utils/helper";
import DownloadAppBtn from "./DownloadAppBtn";
import { twMerge } from "tailwind-merge";

export default function HeadingWithDescription({
  heading,
  description,
  className,
  headerClassName,
  descriptionClassName,
  btnClassName,
  highlightedWords = 2,
}: {
  heading: string;
  description: string;
  className?: string;
  descriptionClassName?: string;
  headerClassName?: string;
  btnClassName?: string;
  highlightedWords?: number;
}) {
  return (
    <div className={cn("space-y-6 md:max-w-3xl lg:max-w-3xl", className)}>
      <h2
        className={cn(
          "text-5xl font-bold !leading-normal  text-Secondary-Dark-Blue-db-500 max-xl:text-4xl  max-md:text-3xl",
          headerClassName,
        )}
      >
        {heading
          .split(" ")
          .slice(0, heading.split(" ").length - highlightedWords)
          .join(" ")}{" "}
        {highlightedWords ? (
          <span className=" bg-Primary-Magic-Mint-Mm-400  ">
            {heading
              .split(" ")
              .slice(heading.split(" ").length - highlightedWords)
              .join(" ")}
          </span>
        ) : null}
      </h2>
      <p
        className={cn(
          "text-justify text-xl font-normal text-Secondary-Dark-Blue-db-300 max-md:text-xl",
          descriptionClassName,
        )}
      >
        {description}
      </p>
      <DownloadAppBtn className={cn("text-base", btnClassName)} />
    </div>
  );
}
